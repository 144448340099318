// api.js
import axios from 'axios';
import { useEffect, useState } from "react";


export function GetVersion() {
  return 3.1;
}




// This is an array of all the possible subscription types by name
// This is used to determine if a subscription is an upgrade or a downgrade
// The lower the index in the array, the lower the subscription level
export const subscriptionTypesUsedForValue = [
  "Founders Image Only Plan",
  "Founders Plan",
  "Founders Image Only Plan Yearly",
  "Founders Plan Yearly",
];

// This function takes two strings, the current subscription type and the selected subscription type
// And returns true if the selected subscription type is an upgrade from the current subscription type
// or false if it is a downgrade
export function isUpgrade(selectedSubscriptionType,currentSubscriptionType) {

  console.log("isUpgrade() called with currentSubscriptionType: ", currentSubscriptionType, " and selectedSubscriptionType: ", selectedSubscriptionType);

  const currentSubscriptionTypeIndex = subscriptionTypesUsedForValue.indexOf(currentSubscriptionType);
  const selectedSubscriptionTypeIndex = subscriptionTypesUsedForValue.indexOf(selectedSubscriptionType);

  return selectedSubscriptionTypeIndex < currentSubscriptionTypeIndex;
}


export function getUserDetails() {
    return axios.get("https://www.craftful.ai/api/auth/", { withCredentials: true });
};

export function logoutUser() {
    return axios.get("https://www.craftful.ai/api/auth/logout", { withCredentials: true });
};

export function createPaymentIntent(email, user_id, customer, paymentType, package_type = null, recurring_type) {
  return axios.post(
    "https://www.craftful.ai/api/payment", 
    { 
      email,
      user_id,
      customer,
      payment_type: paymentType, 
      package_type: package_type,
      recurring_type: recurring_type
    }, 
    { withCredentials: true }
  );
};


export function getAdditionalUserDetails(user_id, password) {
  try {
    const response = axios.post(`https://www.craftful.ai/api/payment/user`,
    { 
      user_id: user_id,
      password: password
    });
  
    return response;
  } catch (error) {
    console.error(`getAdditionalUserDetails: ${error}`);
    throw error;
  }
};




export function getUserPaymentHistory(stripeUserId) {
  try {
    const response = axios.get(`https://www.craftful.ai/api/payment/user/${stripeUserId}`);
    return response;
  } catch (error) {
    console.error(`getAdditionalUserDetails: ${error}`);
    throw error;
  }
};




// This function will return the level of authorization that the currently logged in user has
// for example, "admin" or "user" or "guest"
export const getAuthorizationLevel = async () => {
  try {
      const response = await getUserAuthLevel();
      return response.data;
  } catch (err) {
      console.log(err);
      return null;  // or throw the error if you want it to propagate: throw err;
  }
}

export function getUserAuthLevel() {
  return axios.get(`https://www.craftful.ai/api/auth/authlevel`, { withCredentials: true });
};


/*
  // this function sets the users authorization level
  const setAuthLevel = async (level) => {
    try {
      let response = await setUserAuthLevel("109387743896813568","admin","djo#on&&*ikm,!!10oO5a");
      console.log("setAuthLevel() successfull response: ",response);
    } catch {
      console.log("setAuthLevel() failed");
    }  
  }
*/

export async function setUserAuthLevel(user_id, newAuthLevel, password) {
  return await axios.put("https://www.craftful.ai/api/auth/authlevel", 
    { 
      user_id: user_id,
      newAuthLevel: newAuthLevel,
      password: password
    }, 
    { withCredentials: true }
  );
};


export function deleteUser(user_id, password) {
  return axios.post("https://www.craftful.ai/api/payment/delete_user", 
  // the body of the request
  { 
    user_id: user_id,
    password: password
  });
};


export function processSinglePayment(paymentIntentId, paymentMethodId, couponCode) {
  console.log("Calling process_single with coupon: ", couponCode);
    return axios.post("https://www.craftful.ai/api/payment/process_single", { 
      paymentIntentId: paymentIntentId,
      paymentMethodId: paymentMethodId,
      couponCode: couponCode
    });
};

// Processes a subscription payment
export function processSubscriptionPayment(paymentIntentId, paymentMethodId, couponCode ) {
  return axios.post("https://www.craftful.ai/api/payment/process_subscription", { 
    paymentIntentId: paymentIntentId,
    paymentMethodId: paymentMethodId,
    couponCode: couponCode
  });
};

// function that retrieves a stripe customer's data
export async function getStripeCustomerData(stripeid){
  return await axios.post("https://www.craftful.ai/api/payment/customer", 
  // the body of the request
  { 
    stripeId: stripeid
  });
}

// function that retrieves a stripe customer's data
export async function testTheStripePost(stripeid){
  return await axios.post("https://www.craftful.ai/api/payment/testpost", 
  // the body of the request
  { 
    stripeId: stripeid
  });
}


// redeems a gift code for a customer,creating a new checkout session with the given coupon/gift code
export async function redeemGiftCode( giftCode, email, userId, stripeId ) {
    return await axios.post("api/payment/redeem-gift-code",
    // the body of the request
    {
      giftCode: giftCode,
      email: email,
      userId: userId,
      stripeId: stripeId
    });
  }

// function that retrieves a stripe customer's new gift code
export async function getCustomerGiftCode(stripeid){
  const response =  await axios.post("https://www.craftful.ai/api/payment/get_gift_code", 
  // the body of the request
  { 
    stripeId: stripeid
  });

  return response.data;
}

// function that retrieves a stripe customer's new gift code list
export async function getCustomerGiftCodeList(stripeid){
  const response =  await axios.post("https://www.craftful.ai/api/payment/get_gift_code_list", 
  // the body of the request
  { 
    stripeId: stripeid
  });

  return response.data;
}
// function that updates a stripe customer's data
export async function updateFJCustomer(stripeCustomerId, discordId, updateFields) {
  try {
      const response = await axios.post('https://www.craftful.ai/api/payment/update_fjcustomer', {
          stripeCustomerId,
          discordId,
          updateFields
      });

      return response.data; // Or handle the response as needed
  } catch (error) {
      console.error('Error updating FJ customer:', error);
      throw error; // Or handle the error as needed
  }
}


// updates a customers data in our database
// all fields except for discordid are optional
export function updateCustomerData(user_id, name, email, address, discordname, discordid) {
  return axios.post("https://www.craftful.ai/api/payment/update_customer_data", 
  // the body of the request
  { 
    user_id: user_id,
    name: name,
    email: email, 
    address: address,
    discordname: discordname,
    discordid: discordid
  });
}


// function that updates a stripe customer's data
export async function updateStripeCustomerData(stripeid, name,email,address,discordname,discordid){
  return await axios.post("https://www.craftful.ai/api/payment/update_customer", 
  // the body of the request
  { 
    stripeid: stripeid,
    name: name,
    email: email, 
    address: address,
    discordname: discordname,
    discordid: discordid
  });
}

// function that calls our express api to update a stripe customer's email
// This is the express api function that we are calling
// function that updates a stripe customer's data
export async function updateCustomerEmail(stripeid,email,userid){
  return await axios.put("https://www.craftful.ai/api/payment/update_customer_email", 
  // the body of the request
  { 
    stripeid: stripeid,
    email: email, 
    userid: userid
  });
}

// create a function that create a new stripe customer
export function createStripeCustomer(email, discordname, user_id) {
    return axios.post("https://www.craftful.ai/api/payment/create_customer", 
    // the body of the request
    { 
      email: email, 
      discordname: discordname,
      user_id: user_id 
    });
};

export async function getSubscriptionDetails(stripeid) {
  try {
    const response = await axios.get(`https://www.craftful.ai/api/payment/subscriptionDetails/${stripeid}`);
    return response;
  } catch (error) {
    console.error(`Error getting subscription details: ${error}`);
    throw error;
  }
};


export async function cancelSubscription(stripeid) {
  try {
    const response = await axios.post(`https://www.craftful.ai/api/payment/cancelSubscription/${stripeid}`);
    return response;
  } catch (error) {
    console.error(`Error cancelling subscription: ${error}`);
    throw error;
  }
};

// makes a call to the express api to update the status of the given users subscription
export async function updateSubscriptionStatus(userid) {
  try {
   //console.log("Inside updateSubscriptionStatus, userid: ", userid);
    const response = await axios.get(`https://www.craftful.ai/api/payment/update_subscription_status/${userid}`);
    return response;
  } catch (error) {
    console.error(`Error updating subscription status: ${error}`);
    throw error;
  }
};

// makes a call to the express api to upgrade/downgrade the given users subscription
// using this express route router.post('/updateSubscription', async (req, res)
// newsubscriptiontype is the name of one of the possible subscription types, such as "Founders Plan"
export async function upordowngradeSubscription(subscriptionid, newsubscriptiontype) {
  try {
    const response = await axios.post(`https://www.craftful.ai/api/payment/updateSubscription`,
     {  subscriptionId: subscriptionid, newsubscriptiontype: newsubscriptiontype });
    return response;
  } catch (error) {
    console.error(`Error updating subscription: ${error}`);
    throw error;
  }
};



// **
// makes a call to our express api to renew a users subscription
// if the users subcription is currently active but set to cancel, this will renew it and return true
// but if the user has no active subsciption, it will return false
// if the users subscription is currently active and not set to cancel, this will do nothing and return false
// **
export async function renewSubscription(userid) {
  try {
    const { data } = await axios.post(`https://www.craftful.ai/api/payment/renew_subscription/${userid}`);
   // console.log("response in renewSubscription utils function is", data.whatdidwedo);
    return data; 
  } catch (error) {
    console.error(`Error renewing subscription: ${error}`);
    throw error;
  }
};


// **
// Verifies the coupon id and return the coupon object on success
// using the following route router.post('/verify_coupon', async (req, res) =>... which is 
// at this website location: https://www.craftful.ai/api/payment/verify_coupon
// and puts the couponid code in the body of the request
// **
export async function verifyCoupon(couponid) {
  try {
    const response = await axios.post(`https://www.craftful.ai/api/payment/verify_coupon`, { couponid: couponid });
    return response;
  } catch (error) {
    throw error;
  }
};



export function useResponsiveFontSize() {
  const getFontSize = () => (window.innerWidth < 450 ? "16px" : "18px");
  const [fontSize, setFontSize] = useState(getFontSize);

   //console.log(`Fontsize set to ${fontSize} on ${window.innerWidth}`);

  useEffect(() => {
    const onResize = () => {
     // console.log(`Screen resized, Fontsize set to ${fontSize} on ${window.innerWidth}`);
      setFontSize(getFontSize());
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  });

  return fontSize;
}
