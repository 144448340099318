import React, { useRef, useState, useEffect } from 'react';
import { GetVersion, getUserDetails, logoutUser, updateSubscriptionStatus, getStripeCustomerData,
    updateCustomerEmail, redeemGiftCode} from '../../Utils/api.js';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { EmailUpdateModal } from './emailUpdateModal.jsx';
import { GiftCodeModal } from './GiftCodeModal.jsx'; // Adjust the path as needed
import logo from './craftful-header-nobg.png';
import './index.css';
import styles from './index.module.css';


export function DashboardPage() {
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [update, setUpdate] = useState(0);
    const [subscriptionStatusDisplay, setSubscriptionStatusDisplay] = useState('Fetching subscription status...');
    const [creditsDisplayed,setcreditsDisplayed] = useState('Fetching credits...');
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showGiftCodeModal, setShowGiftCodeModal] = useState(false);
    const dashboardRef = useRef(null); // Make sure to declare the ref here

    const location = useLocation();
    const query = new URLSearchParams(location.search);


    useEffect(() => {
        function centerContentVertically() {
          const dashboard = dashboardRef.current;
          if (dashboard) {
            const contentHeight = dashboard.offsetHeight;
            const viewportHeight = window.innerHeight;
            const topPadding = (viewportHeight - contentHeight) / 2;
    
            if (topPadding > 0) {
              dashboard.style.paddingTop = `${topPadding}px`;
              dashboard.style.paddingBottom = `${topPadding}px`;
            } else {
              dashboard.style.paddingTop = '20px';
              dashboard.style.paddingBottom = '20px';
            }
          }
        }
    
        centerContentVertically();
    
        // Add event listener for when the window is resized or device orientation changes
        window.addEventListener('resize', centerContentVertically);
        window.addEventListener('orientationchange', centerContentVertically);
    
        // Clean up function to remove the event listeners
        return () => {
          window.removeEventListener('resize', centerContentVertically);
          window.removeEventListener('orientationchange', centerContentVertically);
        };
      }, []);

    const processCreditsDisplay = (credits) => {
        if (credits > 14999)
            return "Unlimited";
        else
            return credits;
    };

    
    const doRedeemGiftCode = async (code) => {
        console.log("Attempting to redeem gift code: ", code);
        try {
          let { data } = await redeemGiftCode(code, user.email, user.user_id, user.stripeid);
          console.log(data);
      
          // Redirect the user to the Stripe checkout
          window.location.href = data.url;
        } catch (err) {
          console.log("Error redeeming gift code: ", err);
          return;
        }
        // Add logic to redeem the code
        setShowGiftCodeModal(false); // Close the modal after redeeming
    };

    useEffect(() => {
    console.log("Version is: ",GetVersion());

        if (user) {
            const fetchSubscriptionStatus = async () => {
                try {
                    let response = await updateSubscriptionStatus(user.user_id);
                    let updatedUser = response.data;

                    setSubscriptionStatusDisplay(updatedUser.subscription_status);
                    setcreditsDisplayed(processCreditsDisplay(updatedUser.credits));
                } catch (err) {
                    console.log(err);
                }
            };
            fetchSubscriptionStatus();
        }
    }, [user, update]);

    useEffect(() => {
        if (query.get('refresh') === 'true') {
            setUpdate(update + 1);
        }
    }, [query.get('refresh')]);

    useEffect(() => {       
            getUserDetails().then(({data}) => { 
                setUser(data);
                setLoading(false);

                 // Check if email needs to be updated
                 if (data.stripeid)
                    checkAndUpdateStripeEmail(data);
                 else
                    console.log("No stripeId found for user: ",data);

            }).catch((err) => {
                navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`);
                setLoading(false);
            });        
    }, [navigate, update]);

    const checkAndUpdateStripeEmail = async (userData) => {
        const stripeid = userData.stripeid;

        console.log("UserData is: ",userData);


        // If our database entry has a messed up email, then have the customer update it
        if (userData && (userData.email === 'notyetset@none.com' || !userData.email))
            setShowEmailModal(true);

        // If the stripe database entry has a messed up email, then have the customer update it
        else
        {
            try { 
                //  Get the customer from stripe
                const response1 = await getStripeCustomerData(stripeid);  
                const customerData = response1.data;
                if (customerData && (customerData.email === 'notyetset@none.com' || !customerData.email)) {
                    setShowEmailModal(true);
                }
    
            } catch (error) {
                console.error('Error fetching customer data from Stripe:', error);
            }
        }
    };

    const logoutButton = () => {
        logoutUser().then(() => {
            navigate(`/menu?${process.env.REACT_APP_CURRENT_VERSION}`); 
        }).catch((err) => {
            console.log(err);
        });
    };

    const handleEmailUpdate = async (newEmail) => {
        
        // trim off any whitespace from the email
        newEmail = newEmail.trim();
        
        try {
            // Update the email in  database
            const updateResponse = await updateCustomerEmail(user.stripeid, newEmail, user.user_id);

    
            // Update user state or perform any other actions after successful update
            setUser({ ...user, email: newEmail });
    
            // Close the modal
            setShowEmailModal(false);
    
            // Additional success handling, such as showing a success message
        } catch (error) {
            console.error('Error updating email:', error);
            // Handle any errors, such as showing an error message
        }
    };

    const navigateButton = (path) => {
        navigate(path);        
    };




    // first check if the user data is loaded and exists
    if (!loading && user) {
        return (
            <div className="dashboard-page p-8" ref={dashboardRef}>
            <div className="dashboard-page p-8">
            <img src={logo} className={styles.logo} alt="logo" />
              <div className="flex flex-col">
                {user.subscription_status !== 'free' 
                  ? <button id="testsubscriptionButton" className="bg-blue-500 text-white p-2 m-2 rounded"   style={{borderRadius: "12px"}} onClick={() => navigateButton(`/subscriptionstatus?${process.env.REACT_APP_CURRENT_VERSION}`)}>View Subscription Status</button>
                  : <button id="testsubscriptionButton" className="bg-blue-500 text-white p-2 m-2 rounded subscribe-button-text"   style={{borderRadius: "12px"}} onClick={() => navigateButton(`/subscription?${process.env.REACT_APP_CURRENT_VERSION}`)}>View Subscription Pricing</button>
                }
              </div>
              {/*A Button that calls redeemacode() */}
              <button id="redeemacodeButton" className="bg-blue-500 text-white p-2 m-2 rounded"   style={{borderRadius: "12px"}} onClick={() => setShowGiftCodeModal(true)}>Redeem A Code</button>

              <div className="buttons-container">
              <button id="testcreditsButton" className="bg-blue-500 text-white p-2 m-2 rounded" style={{borderRadius: "12px"}} onClick={() => navigateButton(`/credits?${process.env.REACT_APP_CURRENT_VERSION}`)}>Buy Credits</button>
                <span className="or-text">or</span>
                <button id="giftasubscriptionbutton" className="bg-blue-500 text-white p-2 m-2 rounded" style={{borderRadius: "12px"}} onClick={() => navigateButton(`/subscription?${process.env.REACT_APP_CURRENT_VERSION}&purchaseType=Gift`)}>Gift A Subscription</button>
              </div>
              
              <button id="paymentHistoryButton" className="bg-blue-500 text-white p-2 m-2 rounded"   style={{borderRadius: "12px"}} onClick={() => navigateButton(`/paymenthistory`)}>Payment History</button>
              
              <hr className="divider" />
              <button id="testinpaintingButton" className="text-white p-2 m-2 rounded" style={{backgroundColor: "#4A90E2", borderRadius: "12px", color: "#ffffff"}} onClick={() => window.location.href = "https://studio.craftful.ai"}>CraftFul Studio</button>
            <hr className="divider" />
              <div className="flex flex-col">
                <div className="flex" style={{paddingBottom: '20px'}}>
                  <span style={{width: '200px'}} className="text-lg font-semibold">Discord Name:</span>
                  <span className="text-lg font-semibold">{user.discordname}</span>
                </div>
                <div className="flex" style={{paddingBottom: '20px'}}>
                  <span style={{width: '200px'}} className="text-lg font-semibold">Credits:</span>
                  <span className="text-lg font-semibold">🪙{creditsDisplayed}</span>
                </div>
                <div className="flex" style={{paddingBottom: '20px'}}>
                  <span style={{width: '200px'}} className="text-lg font-semibold">Subscription Status:</span>
                  <span className="text-lg font-semibold">{subscriptionStatusDisplay}</span>
                </div>
                </div>
                <div>
                    <h1>Need Help?</h1>
                    <p className="custom-link text-lg font-semibold mt-4">
                        <Link to="/documentation" className="custom-link"> A list of all the commands</Link>
                    </p>
                    <p className="custom-link text-lg font-semibold mt-4">
                        <Link to="/loradocumentation" className="custom-link">
                            Lora commands <span style={{color: 'yellow'}}>NEW!!!</span>
                        </Link>
                    </p>
                    <p className="custom-link text-lg font-semibold mt-4">
                        <Link to="/makecommanddocumentation" className="custom-link">Image generation commands</Link>
                    </p>
                    <p className="custom-link text-lg font-semibold mt-4">
                        <Link to="/videocommanddocumentation" className="custom-link">Video generation commands</Link>
                    </p> 
                    <p className="custom-link text-lg font-semibold mt-4 mb-1">
                        <Link to="/audiocommanddocumentation" className="custom-link">Audio generation commands</Link>
                    </p>
                    <p className="custom-link text-lg font-semibold mt-4 mb-1">
                        <Link to="/lipsyncdocumentation" className="custom-link">Lipsync generation commands</Link>
                    </p>
                </div>
                <button id="discordButton" className="bg-blue-500 text-white p-2 m-2 rounded" style={{ borderRadius: "12px" }} onClick={() => window.location.href = "https://discord.gg/kukNqTv7jA"}>Join us on Discord!</button>
                <div className={styles.changelogcontainer}>
                    <button className={styles.changelogbutton} onClick={() => window.location.href = `https://www.craftful.ai/changelog?${process.env.REACT_APP_CURRENT_VERSION}`}>
                        Change Log
                    </button>
                </div>
                <button id="logoutButton" className="bg-blue-500 text-white p-2 m-2 rounded" style={{ borderRadius: "12px" }} onClick={logoutButton}>Logout</button>
    
                {showEmailModal && (
                    <EmailUpdateModal
                        onSubmit={handleEmailUpdate}
                        onClose={() => setShowEmailModal(false)}
                    />
                )}

                {showGiftCodeModal && (
                    <GiftCodeModal
                        onRedeem={doRedeemGiftCode}
                        onClose={() => setShowGiftCodeModal(false)}
                    />
                )}
            </div>
            </div>
        );
    } else {
        return (
            <div className="fullscreen-spinner">
                <div className="spinner" id="spinner"></div>
            </div>
        );
    }
}


